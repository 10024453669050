import styles from './Contato.module.css'

function Contato(){
    return(
        <>
        <div className={styles.about} data-aos="fade-up">
          
        </div>

        <div className='container'>
          
          <div className={styles.containerb} data-aos="fade-down">
          <h2>Entre em Contato</h2>
        <p>atendimento@sistemasinsat.com.br</p>
        <p>Monitoramento: (11) 2863-0153</p>
        <p>Televendas: (11) 2863-0153</p>
          </div>



            </div>

        
        </>
    )
}

export default Contato