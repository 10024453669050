import style from './Bottom.module.css'

function Bottom(){
    return(
        <div className={style.bottom}>
            <p>Todos Direitos Reservados</p>
        </div>
    )
}

export default Bottom